// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import { UserResponse } from "./CreateWorkspaceDialogController.web";
import { AddNewMemberToWorkspaceObject } from "./MemberTabController.web";

export const configJSON = require("./config");

export type Props = {
    id: string;
    isDialogOpen: boolean
    onCloseDialog: (data?: AddNewMemberToWorkspaceObject) => void
    classes?: Record<string, string>
    workspaceId: number,
    actionType: string,
}

interface S {
    userList: Array<UserResponse>
    listChosenUser: Array<UserResponse>
    searchText: string
}

interface SS {
}

class AddMemberDialogController extends BlockComponent<Props, S, SS> {
    getListUserId: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            userList: [],
            listChosenUser: [],
            searchText: ""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getListUserId) {
                    this.setState({ userList: responseJson?.data ?? [] })
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        this.getListUser()
    }
    
    getListUser = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.getListUserId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.searchMemberWorkspace}?term=`
        });
    }

    closeDialog = () => {
        this.setState({
            listChosenUser: [],
        })
        this.props.onCloseDialog()
    }

    createAndCloseDialog = () => {
        if (this.validationValue()) {
            const listAccountIds = this.state.listChosenUser.map(item => Number(item.id)) 
            const listAccountEmails = this.state.listChosenUser.map(item => String(item.attributes.email))

            const data = {
                workspace_id: this.props.workspaceId,
                account_ids: listAccountIds,
                emails: listAccountEmails
            }
            this.setState({
                listChosenUser: [],
            })
            this.props.onCloseDialog(data)
        }
    }

    getCurrentCreateColor = () => {
        if (this.validationValue()) {
            return "#684EF3"
        } else return "#94A3B8"
    }

    validationValue = () => {
        if (this.state.listChosenUser.length === 0) {
            return false
        } else return true
    }

    handleChosenUser = (inputVal: UserResponse) => {
        const newListArray = [...this.state.userList].filter((item) => item.attributes.email !== inputVal.attributes.email)
        const newListChosenArray = [...this.state.listChosenUser]
        newListChosenArray.push(inputVal)
        this.setState({ searchText: "", userList: newListArray, listChosenUser: newListChosenArray })
    }

    onRemoveUser = (inputVal: UserResponse) => {
        const newListArray = [...this.state.userList]
        const newListChosenArray = [...this.state.listChosenUser].filter((item) => item.attributes.email !== inputVal.attributes.email)
        newListArray.push(inputVal)
        this.setState({ searchText: "", userList: newListArray, listChosenUser: newListChosenArray })
    }
}

export default AddMemberDialogController;
// Customizable Area End
