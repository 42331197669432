// Customizable Area Start
import React from "react";
import AddMemberDialogController, { configJSON } from "./AddMemberDialogController.web";
import { Dialog, DialogContent, TextField, Avatar } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { UserResponse } from "./CreateWorkspaceDialogController.web";
import { filterOptions } from "./CreateWorkspaceDialog.web";

export class AddMemberDialog extends AddMemberDialogController {
    renderAutocompleteList = () => {
        return (
            <div className={this.props.classes?.inputView}>
                <div className={this.props.classes?.titleText}>{configJSON.addMemberTitle}</div>
                <Autocomplete
                    data-test-id="autocomplete"
                    options={this.state.userList}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={configJSON.addMemberPlaceholder}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                style: webStyles.textInput,
                            }}
                        />
                    )}
                    onChange={(event, inputVal) => {
                        if (inputVal) this.handleChosenUser(inputVal)
                    }}
                    renderOption={(option) => this.renderOptionUser(option, false)}
                    getOptionLabel={() => ''}
                    getOptionSelected={(option, value) => option.attributes?.email === value.attributes?.email}
                    filterOptions={filterOptions}
                />
                <div className={this.props.classes?.viewListUser}>
                    {
                        this.state.listChosenUser.map((item: UserResponse) => {
                            return this.renderOptionUser(item, true)
                        })
                    }
                </div>
            </div>
        )
    }

    renderOptionUser = (option: UserResponse, showRemoveButton: boolean) => {
        return (
            <div key={`user_${option.id}`} className={this.props.classes?.optionAutocomplete}>
                <Avatar alt="avatar" src={option.attributes?.profile_photo || ""} className={this.props.classes?.optionAvaAutocomplete}>
                    {option.attributes?.first_name?.charAt(0)}
                </Avatar>
                <div className={this.props.classes?.viewContentOption}>
                    <p className={this.props.classes?.titleDropDownAutocomplete}>{`${option.attributes?.first_name ?? ""} ${option.attributes?.last_name ?? ""}`}</p>
                    <p className={this.props.classes?.descriptionDropDownAutocomplete}>{option.attributes?.email}</p>
                </div>
                {
                    showRemoveButton && (
                        <div data-test-id={`option_${option.id}`} className={this.props.classes?.removeButton} onClick={() => this.onRemoveUser(option)}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7946 4.37622L7.96598 7.20422L5.13798 4.37622L4.19531 5.31889L7.02331 8.14689L4.19531 10.9749L5.13798 11.9176L7.96598 9.08955L10.7946 11.9176L11.7373 10.9749L8.90931 8.14689L11.7373 5.31889L10.7946 4.37622Z" fill="#30353B"/>
                            </svg>
                            {configJSON.remove}
                        </div>
                    )
                }
            </div>
        )
    }

    renderViewButton = () => {
        const actionType = this.props.actionType;
        return (
            <div className={this.props.classes?.viewButton}>
                <div data-test-id='closeAddMember' className={this.props.classes?.cancelButton} onClick={this.closeDialog}>{configJSON.cancel}</div>
                <div
                    data-test-id='addMember'
                    className={this.props.classes?.createButton}
                    style={{ backgroundColor: this.getCurrentCreateColor() }}
                    onClick={this.createAndCloseDialog}
                >
                    {actionType==="invite"? configJSON.inviteMemberBtnOk:configJSON.add}
                </div>
            </div>
        )
    }

    render() {
        const { isDialogOpen, actionType} = this.props
        return (
            <Dialog
                onClose={() => this.closeDialog()}
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
                fullWidth
                PaperProps={{
                    style: webStyles.viewDialog
                }}
            >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0 }}
                >
                    <div style={webStyles.textTitle}>
                        {actionType==="invite"? configJSON.inviteMemberTitle:configJSON.addMembers}
                    </div>
                    {this.renderAutocompleteList()}
                    {this.renderViewButton()}
                </DialogContent>
            </Dialog>
        );
    }
}

const webStyles = {
    viewDialog: {
        borderRadius: '24px',
        border: '0px solid #D0D2DA',
        maxHeight: "50%",
        backgroundColor: "#fff"
    },
    textTitle: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "33.6px",
        position: "sticky",
        top: 0,
        padding: "24px 32px",
        zIndex: 2,
        borderBottom: '1px solid #F6F5F9',
        backgroundColor: "#fff"
    } as React.CSSProperties,
    iconExpand: { color: "#30353B" },
    textInput: {
        width: "100%",
        height: 48,
        color: "#30353B",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        padding: "0px 20px 0px 10px"
    }
}

export default AddMemberDialog;
// Customizable Area End