// Customizable Area Start
import { ChangeEvent } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import { WorkspaceMember } from "./WorkspaceListController.web";
import { getStorageData } from "../../../../packages/framework/src/Utilities";

export interface TeamObjectResponse {
    team_name: string
    team_id: number
    team_members_count: number
    team_members: Array<WorkspaceMember>
  }

export const listStatus = [
    {
        team_name: "Accepted",
        value: "Accepted"
    },
    {
        team_name: "Pending",
        value: "Pending"
    }
]

export interface AddNewMemberToWorkspaceObject { 
    workspace_id: number;
    account_ids: Array<number>;
    emails: Array<string>;
}

export const configJSON = require("./config.js");

export type Props =  {
    classes?: Record<string, string>
    listUser: Array<WorkspaceMember>
    workspaceId: number
    reloadWorkspace: () => void
    currentUserId: string
}

interface S {
    inputSearch: string
    teamFilter: string
    statusFilter: string
    isAddModalOpen: boolean
    isSnackbarOpen: boolean
    snackbarContent: string
    isAdmin: boolean
    listUser: Array<WorkspaceMember>
    isOpenRemoveDialog: boolean
    listTeam: Array<TeamObjectResponse>
    anchorEl: Element | ((element: Element) => Element) | null | undefined
    currentChosenMember?: WorkspaceMember 
}

interface SS {
  id: string | number;
}

class MemberTabTabViewController extends BlockComponent<Props, S, SS> {
    addNewMemberToWorkspaceId: string = ""
    removeMemberToWorkspaceId: string = ""
    getListTeamId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            inputSearch: "",
            teamFilter: "",
            statusFilter: "",
            isAddModalOpen: false,
            isSnackbarOpen: false,
            snackbarContent: "",
            isAdmin: false,
            isOpenRemoveDialog: false,
            listUser: [],
            listTeam: [],
            anchorEl: null,
            currentChosenMember: undefined
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    receive = (from: string, message: Message) => {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.addNewMemberToWorkspaceId) {
                if (responseJson.message === "Workspace member added successfully") {
                    this.props.reloadWorkspace()
                } else {
                    this.setState({ isSnackbarOpen: true, snackbarContent: responseJson?.error ?? "Cannot add workspace member this time. Please try later" })
                }
            }
            if (apiRequestCallId === this.removeMemberToWorkspaceId) {
                if (responseJson.message) {
                    this.setState({ currentChosenMember: undefined })
                    this.props.reloadWorkspace()
                } else {
                    this.setState({ isSnackbarOpen: true, snackbarContent: responseJson?.error ?? "Cannot remove workspace member this time. Please try later" })
                }
            }
            if (apiRequestCallId === this.getListTeamId) {
                this.setState({ listTeam: (responseJson?.data ?? []) })
            }
        }
    }

    handleClose = () => this.setState({ isSnackbarOpen: false, snackbarContent: "" })

    addNewMemberToWorkspaceAPI = async (dataNewWorkspace: AddNewMemberToWorkspaceObject) => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.addNewMemberToWorkspaceId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "POST",
            body: JSON.stringify(dataNewWorkspace),
            endPoint: `${baseURL}/${configJSON.addMemberToWorkspace}`
        });
    }

    removeMemberToWorkspaceAPI = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        const data = {
            workspace_id: Number(this.props.workspaceId),
            workspace_member_ids: [this.state.currentChosenMember?.id ?? 0]
        }
        this.removeMemberToWorkspaceId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "POST",
            body: JSON.stringify(data),
            endPoint: `${baseURL}/${configJSON.removeMemberToWorkspace}`
        });
    }

    getListTeam = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getListTeamId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.getLisTeams}`
        });
    }

    onChangeInputSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.setState({ inputSearch: event.target.value }, () => {
            this.filterNameUser()
        })
    };

    filterNameUser = () => {
        if (this.state.inputSearch.trim().length === 0) {
            this.setState({ listUser: this.props.listUser })
        } else {
            const listUser = [...this.props.listUser].filter(item => {
                const fullName = item?.first_name ?? "" + item?.last_name ?? ""
                if (fullName !== "" && fullName.toLowerCase().includes(this.state.inputSearch.toLowerCase())) {
                    return true
                } else {
                    if (item.email.toLowerCase().includes(this.state.inputSearch.toLowerCase())) {
                        return true
                    }
                }
                return false
            })
            this.setState({ listUser: listUser })
        }
    }

    onClearTeamFilter = () => {
        this.setState({ teamFilter: "", listUser: this.props.listUser })
    }

    onClearStatusFilter = () => {
        this.setState({ statusFilter: "", listUser: this.props.listUser })
    }

    onChangeTeamFilter = (teamFilter: string) => {
        this.setState({ teamFilter }, () => {
            this.filterTeamListUser()
        })
    }

    onChangeStatusFilter = (statusFilter: string) => {
        this.setState({ statusFilter }, () => {
            this.filterStatusListUser()
        })
    }

    filterStatusListUser = () => {
        const listUser = [...this.props.listUser].filter(item => item.status === this.state.statusFilter)
        this.setState({ listUser: listUser })
    }

    filterTeamListUser = () => {
        const listUser = [...this.props.listUser].filter(item => item.team_name === this.state.teamFilter)
        this.setState({ listUser: listUser })
    }

    onOpenAddModal = () => {
        this.setState({ isAddModalOpen: true })
    }

    onCloseDialog = (data?: AddNewMemberToWorkspaceObject) => {
        this.setState({ isAddModalOpen: false })
        if (data) {
            this.addNewMemberToWorkspaceAPI(data)
        }
    }

    async componentDidMount(): Promise<void> {
        this.checkIsAdmin()
        this.setState({ listUser: this.props.listUser })
        this.getListTeam()
    }

    checkIsAdmin = () => {
        const userAdmin = this.props.listUser.find((item) => item.permission === "Admin")
        this.setState({ isAdmin: userAdmin?.account_id?.toString() === this.props.currentUserId }) 
    }

    onOpenRemoveDialog = () => {
        this.setState({ isOpenRemoveDialog: true })
        this.closeMenu()
    }

    onViewProfile = () => {
        this.closeMenu()
    }

    onCloseRemoveDialog = () => {
        this.setState({ isOpenRemoveDialog: false, currentChosenMember: undefined })
    }

    onOkCloseDialog = () => {
        this.setState({ isOpenRemoveDialog: false })
        this.removeMemberToWorkspaceAPI()
    }

    openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, user: WorkspaceMember) => {
        this.setState({ anchorEl: event.currentTarget, currentChosenMember: user })
    }

    closeMenu = () => {
        this.setState({ anchorEl: null })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevProps.listUser.length !== this.props.listUser.length) {
            this.setState({ listUser: this.props.listUser })
        }
    }
}

export default MemberTabTabViewController;
// Customizable Area End
